import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { Link, graphql } from 'gatsby';
import { X } from 'styled-icons/feather';
import Content from './content';

const GlobalStyle = createGlobalStyle`
    html, body {
        padding: 0;
        margin: 0;
    }
    .ReactModal__Overlay {
        background-color: rgba(0,0,0,0.3) !important;
        z-index: 99999;
        padding: 0 !important;
    }
    .ReactModal__Content {
      background: rgba(0,0,0,0) !important;
      border: 0 !important;
      padding: 0 !important;
      @media (max-width: 1500px) {
        inset: 0 !important;
      }
    }
    .ReactModal__Body--open {
        overflow-y: hidden;
        background: none;
    }
`;

const Wrapper = styled.div`
    background: linear-gradient(
        to right,
        ${({ theme }) => theme.bgColor1},
        ${({ theme }) => theme.bgColor2}
    );
    display: flex;
    flex-direction: column;
    min-height: 100%;
`;

const Close = styled(Link)`
    color: ${({ theme }) => theme.font};
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 99999;
    background: ${props => props.theme.bgColor2};
    border: solid 1px ${({ theme }) => theme.font};
    border-radius: 70px;
    padding: 6px;
    transition: all 0.3s ease-in;
    &:hover {
        background: ${({ theme }) => theme.bgColor1};
        transform: scale(1.2) !important;
    }
    @media (max-width: 1500px) {
        position: fixed;
        bottom: 20px;
        top: auto;
    }
`;
const Ico = styled(X)`
    color: ${({ theme }) => theme.font};
`;

const Projekt = props => {
    const data = props?.data?.allMarkdownRemark?.edges[0]?.node?.frontmatter;
    const html = props?.data?.allMarkdownRemark?.edges[0]?.node?.html;

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <>
                    <Close
                        to="/projekte"
                        className="animated fadeInUp delay-1s"
                    >
                        <Ico size={38} />
                    </Close>
                    <Wrapper className="animated zoomIn faster">
                        <GlobalStyle />

                        <Content data={data} html={html} />
                    </Wrapper>
                </>
            )}
        </ModalRoutingContext.Consumer>
    );
};

export const query = graphql`
    query($slug: String) {
        allMarkdownRemark(filter: { frontmatter: { slug: { eq: $slug } } }) {
            edges {
                node {
                    html
                    frontmatter {
                        title
                        tags
                        gplay
                        astore
                        website
                        slug
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        logo {
                            childImageSharp {
                                fluid(maxWidth: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        galleryImages {
                            alt
                            bild {
                                childImageSharp {
                                    fluid(maxWidth: 300) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        logo {
                            childImageSharp {
                                fluid(maxWidth: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Projekt;
