import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const Wrapper = styled.div``;

const H1 = styled.h1`
    font-size: 3em;
    color: ${({ theme }) => theme.font};
    @media (max-width: 830px) {
        font-size: 2em;
    }
`;

const LogoWrapper = styled.div``;

const Header = ({ data }) => {
    return (
        <Wrapper>
            <LogoWrapper>
                <Img
                    fluid={data.thumbnail.childImageSharp.fluid}
                    alt={data.title}
                />
            </LogoWrapper>
            <H1>{data.title}</H1>
        </Wrapper>
    );
};

export default Header;
