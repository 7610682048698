import React from 'react';
import styled from 'styled-components';
import Gallery from './gallery';
import { GPlay, AStore, WebLink } from '../projects/linkBtns';
import Tags from '../tags';
import ConditionalWrapper from '../conditionalWrapper';
import SiteWrapper from '../siteWrapperBlank';
import Header from './header';

const Outer = styled.div`
    padding: 20px;
    box-sizing: border-box;
`;

const Wrapper = styled.div`
    max-width: 1000px;
    margin: auto;
    background: ${({ theme }) => theme.darker};
    border-radius: 7px;
    overflow: hidden;
`;
const Description = styled.div`
    box-sizing: border-box;
    max-width: 700px;
    margin: 0 auto 20px auto;
    width: 90%;
    background: ${({ theme }) => theme.bgColor1};
    padding: 20px;
    border-radius: 7px;
    line-height: 1.5em;
`;
const LinkWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 0 10px 0;
`;
const TagWrapper = styled.div`
    padding: 20px;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    align-items: center;
    max-width: 700px;
    margin: auto;
    width: 90%;
`;

const Content = ({ data, html }) => {
    console.log(data);

    if (typeof data === 'undefined') {
        return null;
    }

    return (
        <ConditionalWrapper
            condition={true}
            wrapper={children => (
                <SiteWrapper noMinHeight>{children}</SiteWrapper>
            )}
        >
            <Outer>
                <Wrapper className="animated fadeIn delay-06s">
                    <Header data={data} />
                    <Description dangerouslySetInnerHTML={{ __html: html }} />
                    {data?.galleryImages && (
                        <Gallery images={data.galleryImages} />
                    )}
                    <LinkWrapper>
                        {data.astore !== null && <AStore href={data.astore} />}
                        {data.gplay !== null && <GPlay href={data.gplay} />}
                        {data.website !== null && (
                            <WebLink href={data.website} />
                        )}
                    </LinkWrapper>
                    <TagWrapper>
                        <Tags tagString={data.tags} contrast noMaxWidth />
                    </TagWrapper>
                </Wrapper>
            </Outer>
        </ConditionalWrapper>
    );
};

export default Content;
